import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import About from './components/About';
import Footer from './components/Footer';
import Services from './components/Services';
import Contact from './components/contact';

import './styles/global.css';

function App() {
  return (
    <Router>
        <div className="App">
        <NavBar />
            <Routes>
                <Route path="/services" element={<Services />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/" element={<About />} />
            </Routes>
            <Footer />
        </div>
    </Router>
  );
}

export default App;
