import React from 'react';
import '../styles/About.css';
import '../styles/Values.css';
import { FaBalanceScale, FaHandsHelping, FaSmileBeam, FaLightbulb, FaStar, FaHeartbeat } from 'react-icons/fa';
const About = () => {
  
  const valuesList = [
    { icon: <FaBalanceScale />, title: 'Ética', description: 'Ser uma empresa ética nas relações pessoais e comerciais, respeitando o próximo e seu espaço.' },
    { icon: <FaHandsHelping />, title: 'Cooperação', description: 'Trabalhar em conjunto, auxiliando os clientes no atendimento de suas demandas, propiciando uma relação harmoniosa.' },
    { icon: <FaSmileBeam />, title: 'Honestidade', description: 'Ser clara, digna e justa nas relações com os clientes.' },
    { icon: <FaLightbulb />, title: 'Criatividade', description: 'Encontrar soluções e ser inovadora, se mantendo competitiva no mercado.' },
    { icon: <FaStar />, title: 'Qualidade', description: 'Ser eficiente, eficaz e comprometida com o projeto e no atendimento às necessidades dos clientes.' },
    { icon: <FaHeartbeat />, title: 'Segurança e Saúde', description: 'Priorizar o bem-estar físico, social, psicológico, espiritual e ecológico dos nossos colaboradores resultando em satisfação e motivação no ambiente de trabalho.' },
  ];

  return (
    <section id="about">
      <h2>Quem somos</h2>
      
      <p className="intro">
    A Orca Instalações Elétricas Em Rede De Distribuição LTDA é uma empresa que presta atendimento das atividades especializadas e técnicas da área de Engenharia Elétrica e Civil com ênfase em Linha Viva – Energia Elétrica Energizada.
      </p>
      <p className="second-intro">
      Voltada à manutenção em distribuição e transmissão de energia, buscamos manter a qualidade e segurança, considerando a saúde do meio ambiente, em redes e linhas aéreas e subterrâneas.

      Somos uma empresa parceira que zela pelo seu patrimônio. Com operação sólida no mercado, fornecemos serviços especializados com foco na satisfação do cliente e garantia na execução de projetos, dos mais simples aos mais complexos.
      </p>


      <hr className="section-separator" /> 
      
      <div className="about-sections">
        <div className="about-item">
          <i className="icon-name"></i> {/* Use ícones que se relacionem com cada seção */}
          <h3>Missão</h3>
          <p>
          Oferecer soluções eficientes no segmento elétrico, comercializar serviços de pessoal especializado na área de Linha Viva, além de buscar a total satisfação dos clientes.
          </p>
        </div>
        
        <div className="about-item">
          <i className="icon-name"></i>
          <h3 className="real">Visão</h3>
          <p>
          Ser líder na prestação de serviço terceirizado na área de atuação elétrica, com excelência em serviços de qualidade, fidelizando novos clientes e contribuindo com o desenvolvimento e segurança de nossos colaboradores.
          </p>
        </div>
      </div>
      <h2 className="valuers">Nossos Valores</h2>
      <div className="values-grid">
      {valuesList.map(value => (
        <div className="card value-item" key={value.title}>
          <div className="card-icon">
            {React.cloneElement(value.icon, { className: 'icon' })}
          </div>
          <div className="card-content">
            <h3 className="card-title">{value.title}</h3>
            <p className="card-description">{value.description}</p>
          </div>
        </div>
      ))}
    </div>
    </section>
  );
}

export default About;
