import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, useTheme, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import orcaPhoto from '../img/orca.png';
import '../styles/NavBar.css';

function NavBar() {
    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };    

    const drawer = (
            <div>
        <List sx={{
            "& .MuiListItem-root": {
            fontFamily: "'Poppins', sans-serif",
            color: '#edf2f4',
            textDecoration: 'none',
            fontWeight: 600,
            }
        }}>
        {['Home', 'Serviços', 'Contato'].map((text, index) => (
            <ListItem 
                 button 
                 key={text}
                        component={RouterLink} 
                        to={["/", "/services", "/contact"][index]}
                        onClick={handleDrawerToggle}
                    >
                        {text}
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
      <AppBar position="static" sx={{ backgroundColor: "#3367bb", padding: '10px 25px' }}>  {/* padding ajustado */}
        <Toolbar disableGutters={true}> {/* disableGutters remove o padding padrão */}
          {/* Logo */}
          <img src={orcaPhoto} alt="Orca Logo" style={{ width: '75px', marginRight: '20px' }} /> 
          <Typography 
            variant="h5"
            component={RouterLink} 
            to="/" 
            sx={{ 
                flexGrow: 1, 
                textDecoration: 'none', 
                color: 'inherit', 
                fontFamily: 'Poppins, sans-serif', 
                fontSize: "1.5rem", 
                fontWeight: 600  // Adicione esta linha
            }}
        >
            Orca Instalações
        </Typography>


                {isMobile ? (
                    <>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ color: '#edf2f4', marginRight: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor={'right'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            sx={{ '& .MuiDrawer-paper': { width: 220, bgcolor: '#3367bb' } }}
                        >
                            {drawer}
                        </Drawer>
                    </>
                ) : (
                  <List sx={{ display: 'flex', gap: '15px' }}>
                  {['Home', 'Serviços', 'Contato'].map((text, index) => (
                      <ListItem 
                          button 
                          key={text}
                          component={RouterLink} 
                          to={["/", "/services", "/contact"][index]}
                          sx={{ 
                              color: '#edf2f4', 
                              textDecoration: 'none', 
                              fontWeight: 700,  // Font weight que adicionamos anteriormente
                              fontSize: '1.2rem'  // Aqui estamos aumentando a fonte. Ajuste o valor conforme necessário
                          }}
                      >
                          {text}
                      </ListItem>
                  ))}
              </List>              
                )}
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
