import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer>
      <p>Copyright &copy; {(new Date().getFullYear())} Orca Instalações Elétricas Em Rede De Distribuição LTDA. Todos os direitos reservados.</p>
      <p>
        Email: <a href="mailto:contato@orcainstalacoes.com">contato@orcainstalacoes.com</a> | 
        Tel: <a href="tel:+557435419176">+55 74 3541 9176</a>
      </p>
    </footer>
  );
}

export default Footer;
