import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Contact.css';

const Contact = () => {
    return (
        <div className="contact-container">
            <div className="branding">
                <h1>ORCA INSTALAÇÕES</h1>
                <p>Sua energia nos movimenta.</p>
            </div>
            <div className="links">
                <h2>LINKS</h2>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">Quem somos</Link></li>
                    <li><Link to="/services">Serviços</Link></li>
                    <li><a href="http://www.orcaeng.com.br:2095/" target="_blank" rel="noopener noreferrer">Webmail</a></li>
                </ul>
            </div>
            <div className="contact-info">
                <h2>CONTATO</h2>
                <address>
                    Rod. Lomanto Junior, BR 407, 600 <br />
                    Senhor do Bonfim, BA, 48970-000 <br />
                    Brasil <br />
                    Tel: +55 74 3541 9176 <br />
                    Email: contato@orcainstalacoes.com <br />
                </address>
                <p>Diretora: Claudira Dias</p>
                <p>Diretor: Sandro Leonardo</p>
            </div>
        </div>
    );
}

export default Contact;
