import React from 'react';
import '../styles/Services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

const Services = () => {
  const servicesList = [
    { id: 1, name: 'Manutenção', description: ['Redes e Linhas Elétricas Energizadas', 'Subestações de alta e baixa tensão energizadas.'] },
    { id: 2, name: 'Serviços de reparos', description: ['Reforma, ampliação e implantação', 'Manutenção em Redes e Linhas Aéreas e Subterrâneas Energizadas', 'Iluminação em vias públicas'] },
    { id: 3, name: 'Locação', description: ['Veículos e equipamentos'] }
  ];

  const scrollToContent = () => {
    window.scrollTo({
      top: document.getElementById('statistics').offsetTop,
      behavior: 'smooth'
    });
  };

  return (
    <div>
      <section id="services">
        <h2 className="section-header">Nossos Serviços</h2>
        <div className="services-grid">
          {servicesList.map(service => (
            <div className="service-item" key={service.id}>
              <h3>{service.name}</h3>
              <ul>
                {service.description.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>
      <div className="scroll-indicator" onClick={scrollToContent}>
      <FontAwesomeIcon icon={faArrowDown} />
    </div>
      <section id="statistics">
        <h2 className="section-header">Nossas estatísticas</h2>
        <div className="stats-grid">
          <div className="stats-item">
            <h3>Mais de 22.000</h3>
            <p>Manutenções e Obras</p>
          </div>
          <div className="stats-item">
            <h3>Mais de 200.000</h3>
            <p>Horas de Suporte</p>
          </div>
          <div className="stats-item">
            <h3>Mais de 100</h3>
            <p>Trabalhadores especializados em Linha Viva</p>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="spacer"></div>
      </section>
    </div>
  );
}

export default Services;
